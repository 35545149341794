import "./NavBar.scss"
import {Container, Nav, Navbar} from "react-bootstrap";
import {HashLink} from "react-router-hash-link";

export function NavBar() {

    return (
        <div className="app-navbar">

            <Container>
                <div className="navbar-desktop py-5">
                    <div className={"navbar-desktop-items"}>
                        <HashLink className={'nav-link'} to={"#home"}>Home</HashLink>
                        <HashLink className={'nav-link'} to={"#services"}>Services</HashLink>
                        <HashLink className={'nav-link'} to={"#portfolio"}>Portfolio</HashLink>
                        <HashLink className={'nav-link'} to={"#works"}>Works</HashLink>
                        <Nav.Link className={'nav-link'} target={'_blank'} href={"https://forms.gle/mmAHsSTbJwvV39uk7"}>Contact</Nav.Link>
                    </div>
                </div>
            </Container>

            <div className="navbar-mobile">
                <Navbar variant={"light"} expand="lg">
                    <Container className={"py-2"}>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <HashLink className={'nav-link'} to={"#home"}>Home</HashLink>
                                <HashLink className={'nav-link'} to={"#services"}>Services</HashLink>
                                <HashLink className={'nav-link'} to={"#portfolio"}>Portfolio</HashLink>
                                <HashLink className={'nav-link'} to={"#works"}>Works</HashLink>
                                <Nav.Link className={'nav-link'} target={'_blank'} href={"https://forms.gle/mmAHsSTbJwvV39uk7"}>Contact</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </div>
    )

}
