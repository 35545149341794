import "./Footer.scss"
import {Discord, Telegram, Twitter} from "react-bootstrap-icons";
import {Container} from "react-bootstrap";

export function Footer() {

    return (
        <div className="footer py-5">
            <Container>
                <div className="footer-row pb-4">
                    <a href="/#"><Telegram size={25} color={"#ffffff"}/></a>
                    <a href='https://twitter.com/elysium_arts' target={'_blank'} rel="noreferrer"><Twitter size={25}
                                                                                                           color={"#ffffff"}/></a>
                    <a href="/#"><Discord size={25} color={"#ffffff"}/></a>
                </div>
                <div className="footer-text text-center">© 2022, Elysium Arts. All rights reserved.</div>
            </Container>
        </div>
    )
}
