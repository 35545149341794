import "./PortfolioGalleryCard.scss"
import {Fullscreen} from "react-bootstrap-icons";
import {FC} from "react";
import {PhotoView} from "react-photo-view";

export const PortfolioGalleryCard: FC<{ item: { original: string, thumb: string } }> = function (props) {
    return (
        <div className="portfolio-gallery-card m-4">
            <div className="portfolio-card">
                <PhotoView key={props.item.original} src={props.item.original}>
                    <img className={"img-hover"} src={props.item.thumb} width={375} alt=""/>
                </PhotoView>
                <img className={"img-border"} src={props.item.thumb} width={375} alt=""/>
                <div className="card-info">
                    <div className={"full-screen-btn float-end"}><Fullscreen/></div>
                </div>
            </div>
        </div>
    )
}
