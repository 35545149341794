import React from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Homepage} from "./pages/Homepage";
import {NavBar} from "./components/NavBar";
import {Footer} from "./components/Footer";
import {BrowserRouter} from "react-router-dom";
import {PhotoProvider} from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';

function App() {
    return (
        <PhotoProvider>
            <BrowserRouter>
                <div className="App">
                    <NavBar/>
                    <Homepage/>
                    <Footer/>
                </div>
            </BrowserRouter>
        </PhotoProvider>
    );
}

export default App;
