import "./Homepage.scss"
import {Button, Col, Container, Row} from "react-bootstrap";
import {PortfolioGalleryCard} from "../components/PortfolioGalleryCard";

const portfolioData: { original: string, thumb: string }[] = [
    {
        original: '/assets/images/homepage/portfolio/original/1.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/1.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/2.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/2.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/3.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/3.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/4.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/4.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/5.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/5.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/6.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/6.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/7.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/7.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/8.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/8.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/9.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/9.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/10.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/10.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/11.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/11.jpg',
    },
    {
        original: '/assets/images/homepage/portfolio/original/12.jpg',
        thumb: '/assets/images/homepage/portfolio/thumb/12.jpg',
    },
]

export function Homepage() {


    return (
        <div className={"page homepage"}>
            <div id={'home'} className="header">
                <Container>
                    <h1 className={"header-name"}>Elysium Arts</h1>
                    <div className="text-center header-description">Bringing top tier art to the blockchain.<br/>
                        A collective of over 10 incredible artists with the capabilities and capacity to create
                        masterpieces.
                    </div>
                    <a href={'https://forms.gle/mmAHsSTbJwvV39uk7'} style={{textDecoration: 'none'}} target={'_blank'}>
                        <div className="contact-us-btn mt-5"><Button className={"primary-btn"}>Contact Us</Button></div>
                    </a>
                </Container>
                <img className={"h-rule"} src="/assets/images/homepage/hr-rule.png" alt=""/>
            </div>


            <div id={'services'} className="our-services py-5">
                <Container>
                    <div className="py-5">
                        <Row>
                            <Col lg={2}>
                                <h3 className={"pb-5 mt-0 mt-lg-4 text-center text-lg-start"}>Our Services</h3>
                            </Col>
                            <Col lg={10}>
                                <div className="our-services-img-row">
                                    <div className={"m-4 our-services-card"}>
                                        <img src="/assets/images/homepage/our-services/service_01.png" width={300}
                                             alt=""/>
                                        <div className="our-services-card-info">
                                            <div className="p-2">
                                                <h4>World Class Art</h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"m-4 our-services-card"}>
                                        <img src="/assets/images/homepage/our-services/service_02.png" width={300}
                                             alt=""/>
                                        <div className="our-services-card-info">
                                            <div className="p-2">
                                                <h4>Discord Security Management</h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"m-4 our-services-card"}>
                                        <img src="/assets/images/homepage/our-services/service_03.png" width={300}
                                             alt=""/>
                                        <div className="our-services-card-info">
                                            <div className="p-2">
                                                <h4>Collab and Marketing Support</h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <img className={"h-rule"} src="/assets/images/homepage/our-services/hr-rule-2.png" alt=""/>
            </div>


            <div id={'portfolio'} className="portfolio py-5">
                <Container className={"py-5"}>
                    <h3 className={"text-center"}>Portfolio</h3>
                    <div className="portfolio-description text-center mb-5">A small sample of work from our world-class
                        resident artists.
                    </div>
                    <div className="portfolio-gallery pt-5">
                        {portfolioData.map(item => {
                            return <PortfolioGalleryCard item={item}/>;
                        })}
                    </div>
                </Container>
                <img className={"h-rule"} src="/assets/images/homepage/our-services/hr-rule-2.png" alt=""/>
            </div>

            <div id={'works'} className="our-works py-5">
                <Container className={"my-5"}>
                    <h3 className={"text-center"}>Our Works</h3>
                    <div className="our-works-description text-center pb-5">We've worked with some of the top projects
                        in the Solana Ecosystem.
                    </div>
                    <div className="our-works-row">
                        <img className={'mx-4'} src="/assets/images/logos/1.png" width={125} alt=""/>
                        <img className={'mx-4'} src="/assets/images/logos/2.png" width={125} alt=""/>
                        <img className={'mx-4'} src="/assets/images/logos/3.png" width={125} alt=""/>
                        <img className={'mx-4'} src="/assets/images/logos/4.png" width={125} alt=""/>
                        <img className={'mx-4'} src="/assets/images/logos/5.png" width={125} alt=""/>
                    </div>
                </Container>
                <img className={"h-rule"} src="/assets/images/homepage/our-services/hr-rule-2.png" alt=""/>
            </div>

        </div>
    )
}
